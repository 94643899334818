import { useState, useEffect } from 'react';
import { CreatorsListUser } from 'lib/types/users';
import { QUOTES, TWITCH_USER } from 'lib/constants';
import { TestimonialCard } from 'components/TestimonialCard';
import { useApi } from 'lib/contexts/ApplicationState';
import { useRouter } from 'next/router';
import { sample } from 'lodash';
import { ESServiceMeta } from 'server/api/types';

export const FeaturedTestimonial = () => {
  const API = useApi();
  const router = useRouter();
  const [creator, setCreator] = useState<CreatorsListUser | undefined>();

  useEffect(() => {
    function getRandomInt(max: number) {
      return Math.floor(Math.random() * max);
    }

    async function fetchUser(id: number) {
      const response = await API.ignoreErrors(
        API.getTestimonials({ byUserId: [id] }), { data: [], meta: {} as ESServiceMeta },
      );
      if (response?.data?.length) setCreator(response?.data?.[0]);
    }

    if (!creator) {
      const ids = Object.keys(QUOTES).map((k) => Number(k));
      const id = ids[getRandomInt(ids.length - 1)];
      fetchUser(id);
    }

    if (!creator && router.isReady) {
      const { query } = router;
      if (query.source === 'twitchcon') {
        fetchUser(TWITCH_USER);
        return;
      }
      const ids = Object.keys(QUOTES).map((k) => Number(k));
      const id = sample(ids) as number;
      fetchUser(id);
    }
  }, [router, creator]);

  return (
    <>
      <h2 className="text-xl font-semibold pb-4">Featured creator testimonials</h2>
      {creator && <TestimonialCard key={creator.id} user={creator} text={QUOTES[creator.id]} />}
    </>
  );
};

import { firebaseApp } from 'lib/firebase';
import {
  OAuthProvider, getAuth, signInWithPopup,
} from 'firebase/auth';

import { useApplicationState } from 'lib/contexts/ApplicationState';
import AppleIcon from '@mui/icons-material/Apple';
import { logFirebaseDebugInfo } from 'lib/utils/firebaseAuthUtils';
import { useFirebaseAuth } from 'lib/contexts/firebaseAuthContext';
import { useState } from 'react';
import { notify } from 'lib/utils/honeybadgerUtils';
import { isGondolaAppVersionAtLeast, sendAuthRequestToApp } from 'lib/utils/mobileAppUtils';

interface Props {
  returnTo?: string;
  text?: string;
}

export const AppleSignIn = ({ returnTo, text }: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const { api, currentUser } = useApplicationState();
  const auth = getAuth(firebaseApp);
  const to = returnTo || '/';
  const { firebaseUser } = useFirebaseAuth();
  logFirebaseDebugInfo(currentUser, firebaseUser);

  const signInViaWeb = async () => {
    const provider = new OAuthProvider('apple.com');

    signInWithPopup(auth, provider)
      .then(async (userCredential) => {
        // The signed-in user info.
        const { user } = userCredential;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(userCredential);
        const idToken = await user.getIdToken();

        if (credential?.accessToken && idToken && user?.uid && user?.email) {
          await api.signInWithApple({
            ...userCredential,
            idToken,
          });
        }
        window.location.href = to;
      })
      .catch((err) => {
        setErrMsg('There was an issue signing you in. Please contact support@gondola.cc for assistance.');
        notify(err, 'AppleSignIn', { firebaseUser });
      });
  };

  const signInViaApp = () => {
    sendAuthRequestToApp('apple');
  };

  const handleClickSignIn = () => {
    if (isGondolaAppVersionAtLeast('1.0.15')) {
      signInViaApp();
    } else {
      signInViaWeb();
    }
  };

  return (
    <>
      <button
        className="bg-white hover:bg-gray-100 border-mediumgray border rounded-lg text-mediumgray text-xl font-bold py-2 w-full max-w-xs"
        onClick={handleClickSignIn}
        type="button"
      >
        <div className="flex justify-center gap-2">
          <AppleIcon />
          {text || 'Continue with Apple'}
        </div>
      </button>
      <div className="text-red">{errMsg}</div>
    </>
  );
};

import { NextPageContext } from 'next';
import { GondolaHead } from 'components/GondolaHead/GondolaHead';
import { getUserIdFromContext } from 'server/api/services/auth';
import { SignIn } from 'features/SignIn';
import { FirebaseAuthProvider } from 'lib/contexts/firebaseAuthContext';

export const getServerSideProps = async (ctx: NextPageContext) => {
  const currentUserId = await getUserIdFromContext(ctx);

  if (currentUserId) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }
  return {
    props: {},
  };
};

const SigninPage = () => (
  <>
    <GondolaHead
      title="Sign in to Gondola"
      description="Gondola gives you the power to collect, organize, and track social media posts and give credit to the great minds that make them."
      url="https://gondola.cc/signin"
    />
    <FirebaseAuthProvider>
      <SignIn />
    </FirebaseAuthProvider>

  </>
);

// eslint-disable-next-line import/no-default-export
export default SigninPage;

import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { DesktopLogo } from 'components/AppBar/DesktopLogo';
import { FeaturedTestimonial } from 'components/FeaturedTestimonial';
import { useFirebaseAuth } from 'lib/contexts/firebaseAuthContext';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { logFirebaseDebugInfo } from 'lib/utils/firebaseAuthUtils';
import { GoogleSignInWrapper } from './GoogleSignIn';
import { TwitterSignIn } from './TwitterSignIn';
import { EmailSignIn } from './EmailAuth';
import { AppleSignIn } from './AppleSignIn';

export const SignIn = () => {
  const { firebaseUser } = useFirebaseAuth();
  const currentUser = useCurrentUser();
  const router = useRouter();
  const returnTo = router.query?.returnTo || '/';
  const [showEmail, setShowEmail] = useState(false);
  logFirebaseDebugInfo(currentUser, firebaseUser);

  return (
    <div className="flex flex-wrap-reverse items-center md:h-full">
      <div className="h-full bg-teal md:w-1/2 flex items-center justify-center p-6">
        <div className="md:p-6">
          <FeaturedTestimonial />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6 text-center flex items-center">
        <div className="w-full">
          <div className="max-w-[180px] m-auto mb-4">
            <DesktopLogo />
          </div>
          <div className="mb-8">
            {!showEmail && (
              <>
                <h2 className="text-xl mb-4">Choose your sign in method</h2>
                <div className="mb-4">
                  <GoogleSignInWrapper returnTo={returnTo as string} />
                </div>
                <div className="mb-4">
                  <AppleSignIn returnTo={returnTo as string} />
                </div>
                <div className="mb-4">
                  <TwitterSignIn returnTo={returnTo as string} />
                </div>
              </>
            )}
            <div className="mb-4">
              <EmailSignIn onShow={setShowEmail} />
            </div>
          </div>
          {!showEmail && (
            <>
              <div className="text-lg my-4">
                <span className="font-semibold">Don&apos;t have a Gondola account?</span>
                {' '}
                <Link
                  href={{ pathname: '/signup', query: router.query }}
                  className="underline"
                >

                  Sign Up (Free!)

                </Link>
              </div>
              <div className="flex flex-col justify-end">
                <span>
                  By continuing I agree to the
                  {' '}
                  <Link href="/privacy" className="underline">

                    Privacy Policy

                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link href="/terms" className="underline">

                    Terms of Service

                  </Link>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
